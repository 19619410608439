module controllers {
    export module master {
        interface IConsigneeUpdateScope extends ng.IScope {
        }

        interface IConsigneeUpdateScopeParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class consigneeUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'documentRepositoryService',
                'consigneeService',
                'routeCodeService',
                'userAccountService',
                'notifyPartyService',
                'customerService',
            ];


            comId: number;
            consigneeMaster: interfaces.master.IConsigneeMaster;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;
            documentRepository: interfaces.documentManagement.IDocumentRepository;

            constructor(
                private $scope: IConsigneeUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IConsigneeUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public consigneeService: services.master.consigneeService,
                public routeCodeService: services.master.routeCodeService,
                public userAccountService: services.applicationcore.userAccountService,
                public notifyPartyService: services.master.notifyPartyService,
                public customerService: services.master.customerService,

            ) {

                this.comId = $stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadConsignee(this.comId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadConsignee(Id: number) {
                return this.consigneeService.getConsignee(Id).query((data: interfaces.master.IConsigneeMaster) => {

                    this.consigneeMaster = data;
                    this.breadCrumbDesc = this.consigneeMaster.Code + " - " + this.consigneeMaster.Name;
                    this.getDocuments(this.showAll)

                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }

            loadRoutes() {
                return this.routeCodeService.getDropdownListByCountry(this.consigneeMaster.EntityId, false, null, this.consigneeMaster.Country.Id).query({
                }, (resultList) => {
                }).$promise;
            }

            loadResponsibleUsers(searchText: string) {
                return this.userAccountService.getDropdownList(searchText, this.consigneeMaster.EntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            loadNotifyParties() {
                return this.notifyPartyService.getDropdownList().query({
                    ownerEntityId: this.consigneeMaster.EntityId
                }, (resultList) => {
                }).$promise;
            }

            loadCustomers(searchText: string) {
                return this.customerService.getDropdownList(this.consigneeMaster.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            SaveConsignee(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.consigneeService.save().save(this.consigneeMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.comId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveConsignee().then((data: boolean) => {
                            if (data) {
                                this.loadConsignee(this.comId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Consignee.Update", { comId: this.comId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveConsignee().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            getDocuments(showAll:boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.consigneeMaster.Code, systemType: Enum.EnumSystemType.Company, ownerEntityId: this.consigneeMaster.EntityId , showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }
        };

        angular.module("app").controller("consigneeUpdateCtrl", controllers.master.consigneeUpdateCtrl);
    }
}
